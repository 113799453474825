import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import WorkProcessCarouselCard from "../../components/WorkProcessCard/WorkProcessCard";
import { WORK_PROCESS, WORK_PROCESS_SECTION_TITLE } from "../../config/WorkProcessSection.config";

const HowWeWorkSection = () => {
  let deviceSize = useMediaQuery("(max-width:950px)");
  return (
    <div>
      <Grid container className="p-4">
        <Grid item xs={12} className="mb-3">
          <Typography variant={deviceSize ? "h5" : "h3"} className="fw-medium">
            {WORK_PROCESS_SECTION_TITLE}
          </Typography>
        </Grid>
        {WORK_PROCESS.map((process, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} className="p-2" key={index}>
            <WorkProcessCarouselCard 
              CardImage={process.cardImage}
              Description={process.processDescription}
              Title={process.processTitle}
              SubPoints={process.subPoints}
              StepNo={process.processNo}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HowWeWorkSection;
