export const FAQ_SECTION_TITLE = "Frequently asked questions";

export const FAQ_SECTION_QUESTION = [
  {
    question: "How do I schedule a laundry pickup?",
    answer:
      "You can schedule a pickup directly through our app. Simply create an account, select your preferred time, and we’ll be there to pick up your laundry at your convenience.",
  },
  {
    question: "What areas do you serve?",
    answer:
      "We currently serve in Karvenager, Pune. You can check if we deliver to your location by entering your postal code on our app.",
  },
  {
    question: "What services do you offer?",
    answer:
      "We provide a range of services including Wash & Fold, Wash & Iron, Dry Cleaning, Steam Press, and more. Visit our services section for detailed descriptions.",
  },
  {
    question: "What are your prices?",
    answer:
      "Our pricing is competitive and based on the type of service you select. Check our price list on the app for detailed rates.",
  },
  {
    question: "How do I pay for the services?",
    answer:
      "We offer multiple payment options such as online payments through the UPI App, and cash on delivery.",
  },
  {
    question: "Is there a minimum order requirement?",
    answer:
      "Yes, we have a minimum order amount of ₹350 to qualify for free pickup and delivery.",
  },
  {
    question: "What if I need my laundry done urgently?",
    answer:
      "We offer express services for customers who need their laundry cleaned and delivered within 24 hours.",
  },
  {
    question: "How long does it take to get my clothes back?",
    answer:
      "Standard turnaround time is 2-3 days, depending on the type of service. Express services are also available for urgent requests.",
  },
  {
    question: "What kind of detergents and cleaning products do we use?",
    answer:
      "We use eco-friendly, non-toxic products that are safe for your clothes and the environment.",
  },
  {
    question: "How can I track my order?",
    answer:
      "Once your order is confirmed, you can track its status in real-time through our app. You’ll also receive notifications at each stage of the process.",
  },
  {
    question: "What happens if my clothes are damaged?",
    answer:
      "We take the utmost care with your garments. However, in the rare case of damage, please report it within 24 hours of receiving your order, and we’ll resolve the issue promptly.",
  },
  {
    question: "Can I cancel or reschedule my pickup?",
    answer:
      "Yes, you can cancel or reschedule your pickup by logging into your account on the app. Please note that changes should be made at least 2 hours before the scheduled pickup time.",
  },
];
