import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Icons } from "../../config/MUIIcons.config";
import { APP_THEME } from "../../Theme/Theme";

const FAQSectionAccordian = ({ AccordianData }) => {
  const [dataToBeDisplay, setDataToBeDisplay] = useState(
    [...AccordianData].slice(0, 6)
  );
  const [moreOrLessText, setMoreOrLessText] = useState("Show More");

  const handleLessOrMore = () => {
    if (moreOrLessText === "Show More") {
      setDataToBeDisplay([...AccordianData]);
      setMoreOrLessText("Show Less");
    } else {
      const filtered_skills = AccordianData.slice(0, 6);
      setDataToBeDisplay([...filtered_skills]);
      setMoreOrLessText("Show More");
    }
  };

  return (
    <div style={{ maxWidth: "1300px", margin: "0 auto" }}>
      {dataToBeDisplay.map((faq, index) => (
        <Accordion key={index} sx={{ marginBottom: "10px" }}>
          <AccordionSummary
            expandIcon={<Icons.ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className="py-1 px-3"
            sx={{ backgroundColor: APP_THEME.APP_PRIMARY_LIGHT }}
          >
            <Typography align="left">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails
            className="py-3 px-3"
            sx={{ backgroundColor: "#fff" }}
          >
            <Typography align="left">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="my-3 cursor-pointer text-decoration-underline">
        <Typography align="left" onClick={handleLessOrMore}>
            {moreOrLessText}...
        </Typography>
      </div>
    </div>
  );
};

export default FAQSectionAccordian;
