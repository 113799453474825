import CollectClothes from "../assets/WorkProcessSection/CollectCloth.jpg";
import RequestPickup from "../assets/WorkProcessSection/RequestPickup.jpg";
import ExpertCleaning from "../assets/WorkProcessSection/ExpertCleaning.jpg";
import FreshDelivered from "../assets/WorkProcessSection/FreshDelivered.jpg";

export const WORK_PROCESS_SECTION_TITLE = "Our process to clean your clothes"

export const WORK_PROCESS = [
  {
    processTitle: "Collect Your Clothes",
    processNo: 1,
    cardImage: CollectClothes,
    processDescription:
      "Simplify the start of your laundry journey with a hassle-free collection.",
    subPoints: [
      {
        title: "Quick Sorting",
        description:
          "Group your laundry by fabric type or color for the best care.",
      },
      {
        title: "Pack It Up",
        description:
          "Neatly place your clothes in our provided laundry bags for easy transport.",
      },
    ],
  },
  {
    processTitle: "Request a Pickup",
    processNo: 2,
    cardImage: RequestPickup,
    processDescription:
      "Choose the most convenient time for us to pick up your laundry.",
    subPoints: [
      {
        title: "Flexible Scheduling",
        description: "Select a convenient time slot, which is best fits your schedule.",
      },
      {
        title: "Contact-Free Service",
        description:
          "Enjoy our safe, no-contact collection from your doorstep.",
      },
    ],
  },
  {
    processTitle: "Expert Cleaning",
    processNo: 3,
    cardImage: ExpertCleaning,
    processDescription:
      "We treat your clothes with top-notch care and the best cleaning techniques.",
    subPoints: [
      {
        title: "Tailored Treatment",
        description:
          "We assess and clean each item according to its specific needs.",
      },
      {
        title: "Eco-Friendly Products",
        description: "We use gentle, environmentally friendly detergents.",
      },
    ],
  },
  {
    processTitle: "Delivered Fresh",
    processNo: 4,
    cardImage: FreshDelivered,
    processDescription:
      "Get your laundry back fresh, clean, and perfectly folded.",
    subPoints: [
      {
        title: "On-Time Delivery",
        description: "Your clothes will be returned promptly as promised.",
      },
      {
        title: "Freshly Packed",
        description:
          "Enjoy clothes that are folded and packed with care, ready to wear.",
      },
    ],
  },
];
