import React from "react";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const ServicesCard = ({ CardImage, CardTitle, Description }) => {
  return (
    <Card className="rounded rounded-3">
      <div className="w-100 text-center p-2 mt-3">
        <img src={CardImage} alt="Services-Images" style={{width: '80px', height: '80px'}}/>
      </div>
      <CardContent>
        <Typography variant="h6" component="div" className="mb-3">
          {CardTitle}
        </Typography>
        <Typography variant="body2" className="my-1">
          {Description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServicesCard;
