import React from "react";
import { Grid } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { Typography, useMediaQuery } from "@mui/material";
import { Icons } from "../../config/MUIIcons.config";
import logo_tertiary from "../../assets/HeroSection/logo_tertiary.png";
import { APP_THEME } from "../../Theme/Theme";

const FooterSection = () => {
  let deviceSize = useMediaQuery("(max-width:599px)");
  let SocialIconSize = deviceSize ? "28px" : "38px";
  return (
    <div style={{ backgroundColor: APP_THEME.APP_PRIMARY_LIGHT }}>
      <Grid container className="p-4">
        <Grid item xs={12}>
          <div>
            <img
              src={logo_tertiary}
              style={{ width: deviceSize ? "70%" : "30%" }}
              alt="WashUp Tertiary Logo"
              className="img-fluid"
            />
          </div>
          <div className="my-3 d-flex align-items-center justify-content-center gap-3 text-center">
            <Icons.InstagramIcon sx={{ fontSize: SocialIconSize }} />
            <Icons.LinkedInIcon sx={{ fontSize: SocialIconSize }} />
            <Icons.FacebookIcon sx={{ fontSize: SocialIconSize }} />
            <Icons.TwitterIcon sx={{ fontSize: SocialIconSize }} />
          </div>
          <Divider
            sx={{
              backgroundColor: "black",
              height: "2px",
            }}
            role="grid"
            className="my-2"
            variant="fullWidth"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className="my-2">
            {"© "}
            {new Date().getFullYear()}{" Agileworks All Rights Reserved."}
          </Typography>
          {/* <Typography variant="subtitle2" className="my-2">
            Designed & Developed by Shreyas Bhumkar +91 7020175633
          </Typography> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterSection;
