import photo_1 from "../assets/TestimonialsSection/review-1.jpg"
import photo_2 from "../assets/TestimonialsSection/review-2.png"
import photo_3 from "../assets/TestimonialsSection/review-3.jpg"
import photo_4 from "../assets/TestimonialsSection/review-4.jpg"

export const TESTIMONIALS_SECTION_TITLE = "Our customer say's"

export const TESTIMONIALS = [
  {
    photo: photo_1,
    feedback:
      "I've been using their laundry service for months, and I can't praise them enough. The attention to detail is impressive, and my clothes are always returned spotless and well-folded. The convenience of home pickup and delivery is unmatched!",
    name: "Pratik Sonar",
    city: "Pune",
    rating: 5,
  },
  {
    photo: photo_2,
    feedback:
      "This service has been a lifesaver for my busy schedule. The staff is very professional, and they always meet the promised delivery time. Plus, their affordable pricing doesn't compromise the quality. I’m definitely sticking with them!",
    name: "Ashitosh Pataskar",
    city: "Pune",
    rating: 4,
  },
  {
    photo: photo_3,
    feedback:
      "I had a few clothes with stubborn stains that I thought would never come out. To my surprise, they managed to get rid of all the stains! The service was fast and efficient, and the customer support team was very helpful and kind.",
    name: "Shreyas Bhumkar",
    city: "Pune",
    rating: 4,
  },
  {
    photo: photo_4,
    feedback:
      "What I love most about this laundry service is their commitment to eco-friendly cleaning. I feel good knowing my clothes are being handled with sustainable practices. The quality of cleaning is top-notch, and their team is always courteous.",
    name: "Mohanish Saim",
    city: "Pune",
    rating: 4.5,
  }
];
