import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import ServicesCard from "../../components/ServicesCard/ServicesCard";
import { SERVICES, SERVICES_SECTION_TITLE } from "../../config/ServicesSection.config";
import { APP_THEME } from "../../Theme/Theme";

const ServicesSection = () => {
  let deviceSize = useMediaQuery("(max-width:950px)");
  const services_container_BG = APP_THEME.APP_PRIMARY_LIGHT;
  return (
    <div style={{ backgroundColor: services_container_BG }}>
      <Grid container className="p-4">
        <Grid item xs={12} className="mb-3">
          <Typography variant={deviceSize ? "h5" : "h3"} className="fw-medium">
            {SERVICES_SECTION_TITLE}
          </Typography>
        </Grid>
        {SERVICES.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={index} className="p-2">
            <ServicesCard
              key={index}
              CardImage={service.icon}
              CardTitle={service.title}
              Description={service.description}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ServicesSection;
