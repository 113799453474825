import QualityCare from "../assets/WhyChooseUsSection/QualityCare.png"
import AffordablePricing from "../assets/WhyChooseUsSection/AffordablePricing.png"
import FastTurnaround from "../assets/WhyChooseUsSection/FastTurnaround.png"
import EcoFriendlySolutions from "../assets/WhyChooseUsSection/EcoFriendlySolutions.png"
import FreePickupAndDelivery from "../assets/WhyChooseUsSection/FreePickupAndDelivery.png"
import ExpertStainRemoval from "../assets/WhyChooseUsSection/ExpertStainRemoval.png"
import SafeAndSecureHandling from "../assets/WhyChooseUsSection/SafeAndSecureHandling.png"
import CustomizablePreferences from "../assets/WhyChooseUsSection/CustomizablePreferences.png"


export const WHY_CHOOSE_US_SECTION_TITLE = "Why choose us"

export const WHY_CHOOSE_US = [
  {
      title: "Quality Care",
      icon: QualityCare,  // Add an icon class or image URL
      description: "We handle your clothes with the utmost care, ensuring every item is cleaned and returned in pristine condition."
  },
  {
      title: "Affordable Pricing",
      icon: AffordablePricing,  // Add an icon class or image URL
      description: "Get premium laundry services at competitive prices that won't break the bank."
  },
  {
      title: "Fast Turnaround",
      icon: FastTurnaround,  // Add an icon class or image URL
      description: "We guarantee quick service with delivery times suited to your convenience."
  },
  {
      title: "Eco-Friendly Solutions",
      icon: EcoFriendlySolutions,  // Add an icon class or image URL
      description: "Our cleaning process uses environmentally friendly products, ensuring sustainability while taking care of your clothes."
  },
  {
      title: "Free Pickup & Delivery",
      icon: FreePickupAndDelivery,  // Add an icon class or image URL
      description: "Enjoy the convenience of free pickup and delivery with every order above ₹350, right at your doorstep."
  },
  {
      title: "Expert Stain Removal",
      icon: ExpertStainRemoval,  // Add an icon class or image URL
      description: "Our experts specialize in removing tough stains, ensuring your clothes look brand new."
  },
  {
      title: "Safe & Secure Handling",
      icon: SafeAndSecureHandling,  // Add an icon class or image URL
      description: "We prioritize the safety of your clothes with secure handling from start to finish."
  },
  {
      title: "Customizable Preferences",
      icon: CustomizablePreferences,  // Add an icon class or image URL
      description: "Tailor your laundry preferences like detergent type, folding styles, and more for a personalized experience."
  }
];

