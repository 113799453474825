import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Icons = {
  InstagramIcon: InstagramIcon,
  LinkedInIcon: LinkedInIcon,
  TwitterIcon: TwitterIcon,
  FacebookIcon: FacebookIcon,
  ExpandMoreIcon: ExpandMoreIcon,
};
