import React from "react";
import { Typography, Card, CardContent } from "@material-ui/core";
import { Rating } from "@mui/material";
import { APP_THEME } from "../../Theme/Theme";
import Quote_1 from "../../assets/TestimonialsSection/QuotationMark-1.svg";
import Quote_2 from "../../assets/TestimonialsSection/QuotationMark-2.svg";

const TestimonialCard = ({ CardImage, CustomerFeedback, Name, City, CustomerRating }) => {
  return (
    <Card className="rounded rounded-3" style={{ backgroundColor: APP_THEME.APP_PRIMARY_LIGHT }}>
      <CardContent>
        <div className="w-100 text-center p-2 mt-2">
          <img
            src={CardImage}
            alt="Testimonial-Image"
            style={{ width: "80px", height: "80px", borderRadius: '50%' }}
          />
        </div>

        <div className="rounded rounded-3 px-3 py-1">
          <div className="text-start">
            <img
              src={Quote_1}
              alt="quote"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <Typography variant="body1">
            {CustomerFeedback}
          </Typography>
          <div className="text-end">
            <img
              src={Quote_2}
              alt="quote"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
        </div>

        <Typography variant="body1" color="textPrimary" className="my-2">
          {`${Name}, ${City}`}
        </Typography>

        <Rating name="read-only" defaultValue={CustomerRating} precision={0.5} readOnly size="small" />
      </CardContent>
    </Card>
  );
};

export default TestimonialCard;
