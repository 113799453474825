import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import {
  TESTIMONIALS_SECTION_TITLE,
  TESTIMONIALS,
} from "../../config/TestimonialsSection.config";
import TestimonialCard from "../../components/TestimonialsCard/TestimonialCard";

const TestimonialsSection = () => {
  let deviceSize = useMediaQuery("(max-width:950px)");
  return (
    <div>
      <Grid container className="p-4">
        <Grid item xs={12} className="mb-3">
          <Typography variant={deviceSize ? "h5" : "h3"} className="fw-medium">
            {TESTIMONIALS_SECTION_TITLE}
          </Typography>
        </Grid>
        {TESTIMONIALS.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={index} className="p-2">
            <TestimonialCard 
              key={index}
              CardImage={testimonial.photo}
              CustomerFeedback={testimonial.feedback}
              Name={testimonial.name}
              City={testimonial.city}
              CustomerRating={testimonial.rating}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TestimonialsSection;
