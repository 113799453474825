import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { APP_THEME } from "../../Theme/Theme";
import {
  WHY_CHOOSE_US,
  WHY_CHOOSE_US_SECTION_TITLE,
} from "../../config/WhyChooseUsSection.config";
import WhyChooseUsCard from "../../components/WhyChooseUsCard/WhyChooseUsCard";

const WhyChooseUsSection = () => {
  let deviceSize = useMediaQuery("(max-width:950px)");
  const services_container_BG = APP_THEME.APP_PRIMARY_LIGHT;
  return (
    <div style={{ backgroundColor: services_container_BG }}>
      <Grid container className="p-4">
        <Grid item xs={12} className="mb-3">
          <Typography variant={deviceSize ? "h5" : "h3"} className="fw-medium">
            {WHY_CHOOSE_US_SECTION_TITLE}
          </Typography>
        </Grid>
        {WHY_CHOOSE_US.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={index} className="p-2">
            <WhyChooseUsCard
              Description={service.description}
              key={index}
              Image={service.icon}
              Title={service.title}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default WhyChooseUsSection;
