import { Typography } from "@material-ui/core";
import React from "react";
import { APP_THEME } from "../../Theme/Theme";

const WhyChooseUsCard = ({ Image, Title, Description }) => {
  const countDivStyle = {
    width: "65px",
    height: "65px",
    padding: "10px",
    borderRadius: "50%",
    background: APP_THEME.APP_PRIMARY_GRADIENT,
  };
  const imgStyle = {
    aspectRatio: 1,
    height: "100%",
    width: "100%",
  };
  return (
    <div>
      <div
        style={countDivStyle}
        className="mx-auto my-3"
      >
        <img src={Image} alt={`${Title}-img`} style={imgStyle} />
      </div>
      <Typography variant="h6" className="fw-medium mb-2">
        {Title}
      </Typography>
      <Typography variant="body2" className="text-muted">
        {Description}
      </Typography>
    </div>
  );
};

export default WhyChooseUsCard;
