import './App.css';
import HeroSection from './Sections/HeroSection/HeroSection';
import HowWeWorkSection from './Sections/HowDoesWeWorkSection/HowWeWorkSection';
import ServicesSection from './Sections/ServicesSection/ServicesSection';
import TestimonialsSection from './Sections/TestimonialsSection/TestimonialsSection';
import WhyChooseUsSection from './Sections/WhyChooseUsSection/WhyChooseUsSection';
// import DownloadAppSection from './Sections/DownloadAppSection/DownloadAppSection';
import FAQSection from './Sections/FAQSection/FAQSection';
import FooterSection from './Sections/FooterSection/FooterSection';

function App() {
  return (
    <div className="App">
      <HeroSection />
      <ServicesSection />
      <HowWeWorkSection />
      <WhyChooseUsSection />
      <TestimonialsSection />
      <FAQSection />
      {/* <DownloadAppSection /> */}
      <FooterSection />
    </div>
  );
}

export default App;
