import WashAndFold from "../assets/ServicesSection/WashAndFold.png";
import WashAndIron from "../assets/ServicesSection/WashAndIron.png";
import DryCleaning from "../assets/ServicesSection/DryCleaning.png";
import PremiumDryCleaning from "../assets/ServicesSection/PremiumDryCleaning.png";
import SteamPress from "../assets/ServicesSection/SteamPress.png";
import Starching from "../assets/ServicesSection/Starching.png";
import PremiumLaundry from "../assets/ServicesSection/PremiumLaundry.png";
import PremiumSteamPress from "../assets/ServicesSection/PremiumSteamPress.png";

export const SERVICES_SECTION_TITLE = "Explore our services"

export const SERVICES = [
  {
    title: "Wash & Fold",
    icon: WashAndFold,
    description:
      "Get your clothes washed, dried, and neatly folded, ready to wear or store, with no hassle.",
  },
  {
    title: "Wash & Iron",
    icon: WashAndIron,
    description:
      "Freshly washed and crisply ironed clothes, perfect for a sharp, professional look.",
  },
  {
    title: "Dry Cleaning",
    icon: DryCleaning,
    description:
      "Delicate fabric care with our professional dry cleaning to remove stains and maintain quality.",
  },
  {
    title: "Premium Dry Cleaning",
    icon: PremiumDryCleaning,
    description:
      "A luxury dry-cleaning experience with extra care for your finest garments.",
  },
  {
    title: "Steam Press",
    icon: SteamPress,
    description:
      "Gentle yet effective wrinkle removal, keeping your clothes looking fresh and crisp.",
  },
  {
    title: "Starching",
    icon: Starching,
    description:
      "Add a sharp, long-lasting finish to your clothes with our starching service.",
  },
  {
    title: "Premium Laundry",
    icon: PremiumLaundry,
    description:
      "A high-end laundry service offering thorough cleaning, gentle care, and impeccable finish.",
  },
  {
    title: "Premium Steam Press",
    icon: PremiumSteamPress,
    description:
      "Top-tier steam pressing for a perfectly polished look with the utmost care for your fabrics.",
  },
];
