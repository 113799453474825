import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import {
  FAQ_SECTION_TITLE,
  FAQ_SECTION_QUESTION,
} from "../../config/FAQSection.config";
import FAQSectionAccordian from "../../components/FAQSectionAccordian/FAQSectionAccordian";

const FAQSection = () => {
  let deviceSize = useMediaQuery("(max-width:950px)");
  return (
    <div>
      <Grid container className="p-4">
        <Grid item xs={12} className="mb-3">
          <Typography variant={deviceSize ? "h5" : "h3"} className="fw-medium">
            {FAQ_SECTION_TITLE}
          </Typography>
        </Grid>
        <Grid item xs={12} className="p-2">
          <FAQSectionAccordian AccordianData={FAQ_SECTION_QUESTION} />
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQSection;
