import React from "react";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const WorkProcessCarouselCard = ({
  CardImage,
  Title,
  Description,
  SubPoints,
  StepNo,
}) => {
  const countDivStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "4px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${CardImage})`,
  };

  return (
    <React.Fragment>
      <Card className="rounded rounded-3">
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{
            ...countDivStyle,
          }}
        ></div>
        <CardContent className="text-start">
          <Typography variant="h6" component="div" className="mb-3">
            {`${StepNo}. ${Title}`}
          </Typography>
          <Typography variant="body2" className="my-1">
            {Description}
          </Typography>
          <div>
            {SubPoints.map((point, index) => (
              <div className="d-flex gap-3 my-3" key={index}>
                <div>
                  <CheckCircle style={{color: 'green', fontSize: 30}} />
                </div>
                <div>
                  <Typography variant="subtitle1" className="fw-medium mb-1">{point?.title}</Typography>
                  <Typography variant="body2" className="text-muted">
                    {point?.description}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default WorkProcessCarouselCard;
